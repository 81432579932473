import React from 'react';
import './app-button.scss';
import AppStore from '../../assets/images/app_store_badge.svg';
import PlayStore from '../../assets/images/play_store_badge.svg';
import { Box, Link } from '@mui/material';

export default function AppButton() {
  // if (isAndroid)
  //   return (
  //     <div className="download-btn">
  //       <a store="ios" href="https://apps.apple.com/us/app/hezzly/id1580288062" title="Hezzly IOS Mobile" className="ios-btn" />
  //     </div>
  //   );

  // if (isIOS) {
  //   return (
  //     <div className="download-btn">
  //       <a href="https://play.google.com/store/apps/details?id=com.hezzly" title="Hezzly Android Mobile" className="android-btn" />
  //     </div>
  //   );
  // }

  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
        m: 1,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Link
          href="https://play.google.com/store/apps/details?id=com.hezzly"
          rel="noreferrer noopener"
          target="_blank"
          title="Hezzly Android Mobile"
        >
          <img src={`${PlayStore}`} alt="Hezzly sur android" />
        </Link>
      </Box>
      <Box sx={{ alignSlef: 'flex-end' }}>
        <Link
          href="https://apps.apple.com/fr/app/card4ever/id1580288062"
          rel="noreferrer noopener"
          target="_blank"
          title="Hezzly IOS Mobile"
        >
          <img src={`${AppStore}`} alt="Hezzly sur ios" />
        </Link>
      </Box>
    </Box>
  );
}
